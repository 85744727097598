import {
  Environment,
  ScrollControls,
  useAnimations,
  useGLTF,
  useScroll
} from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef, useState } from "react";
import * as THREE from "three";
window.onload = function () {
  // Hide the preloader when both assets are loaded and window.onload
  const preloader = document.querySelector(".preloader1");
  if (preloader) {
    
    setTimeout(() => {
      preloader.style.transition = "opacity 0.5s";
      preloader.style.opacity = "0";
    }, 1000);

    setTimeout(() => {
      preloader.style.display = "none";
    }, 1500); 
    
  }
};  
function Safe() {
  const scroll = useScroll();
  const { camera } = useThree();
  const XCONSTANT = -8.6;
  const YCONSTANT = 1;
  const ZCONSTANT = 45.0;
  const XCONSTANTR = 0;
  const YCONSTANTR = -0.2;
  const ZCONSTANTR = 0;

  const { scene, nodes, animations } = useGLTF(
    "./Animated_Safe/Safe/newSafe.glb",
    null,
    true
  );
  useEffect(() => {
    window.onload = function () {
      // Hide the preloader when both assets are loaded and window.onload
      const preloader = document.querySelector(".preloader1");
      if (preloader) {
        
        setTimeout(() => {
          preloader.style.transition = "opacity 0.5s";
          preloader.style.opacity = "0";
        }, 1000);

        setTimeout(() => {
          preloader.style.display = "none";
        }, 1500); 
        
      }
    };  }, []);
  const { actions } = useAnimations(animations, scene);
  const [isPattern, setIsPattern] = useState(false);

  useEffect(() => {
    Object.values(actions).forEach((action, idx) => {
      action.play().paused = true;
    });
  }, []);

  scene.traverse(function (node) {
    if (node.isMesh && node.name.includes("Plane")) {
      if (node.material.normalMap) {
        node.material.normalMap = null;
      }
    }
    if (
      node.isMesh &&
      (node.name.includes("Cube1268_cell") || node.name.includes("Plane"))
    ) {
      // node.material.color= new THREE.Color(0xffffff)  

      useFrame((state, delta) => {
        const t = state.clock.getElapsedTime();
        node.rotation.x = -33 + t * 0.1;
        node.rotation.y = t * 0.1;
        node.rotation.z = (1 + t * 0.1) / 20;
        node.position.y = (1 + Math.sin(t * 0.4)) / 10;
      });
    } else if (node.isMesh && node.name.includes("Cube1268_cell") ) {
        // node.material.color= new THREE.Color(0xffffff)  

}
  });

  useFrame((state, delta) => {
    const offset = scroll.offset;


    Object.values(actions).forEach((action) => {
      action.time = THREE.MathUtils.damp(
        action.time,
        action.getClip().duration * (offset),
        100,
        delta
      );
    });

    state.camera.position.set(
      Math.sin(offset) * XCONSTANT,
      Math.atan(offset * Math.PI * 10) * YCONSTANT,
      Math.cos((offset * Math.PI) / 3) * ZCONSTANT
    );

    state.camera.rotation.set(
      Math.sin(offset) * XCONSTANTR,
      Math.atan(offset * Math.PI * 10) * YCONSTANTR,
      Math.cos((offset * Math.PI) / 3) * ZCONSTANTR
    );
  });

  return (
    <>
      <primitive object={scene}></primitive>
    </>
  );
}

function PointLightControls() {
  const pointLightRef = useRef();

  // const { color, positionX, positionY, positionZ } = useControls({
  //   color: "#085e83",
  //   positionX: 10,
  //   positionY: 10,
  //   positionZ: 10,
  // });

  return (
    <directionalLight
      ref={pointLightRef}
      color={"#085e83"}
      position={[10, 10, 10]}
      intensity={4}
    />
  );
}

const SmokeMaterial = {
  uniforms: {
    time: { value: 0.0 },
    resolution: { value: new THREE.Vector2() },
    speed: { value: 0.0 },
  },
  transparent: true,
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,

  fragmentShader: `
    uniform float time;
    uniform vec2 resolution;
    varying vec2 vUv;
    uniform float speed;

    float random (in vec2 st) {
      return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
    }
    
    float noise(in vec2 st) {
      vec2 i = floor(st);
      vec2 f = fract(st);
      float a = random(i);
      float b = random(i + vec2(1.0, 0.0));
      float c = random(i + vec2(0.0, 1.0));
      float d = random(i + vec2(1.0, 1.0));
      vec2 u = f * f * (3.0 - 2.0 * f);
      return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }
    
    float fbm(vec2 st) {
      float value = 0.0;
      float amplitud =0.7;
      for (int i = 0; i < 6; i++) {
        value += amplitud * noise(st);
        st *= 2.;
        amplitud *= .4;
      }
      return value;
    }
    
    void main() {
      vec2 uv = vUv;
      vec3 color = vec3(0.001,0.074,0.912);
      color += fbm(uv * 6.0 + time * 0.14 + noise(uv));
      color += fbm(uv * 7.128 + time * -0.1);
      gl_FragColor = vec4(-color, 0.14);
      gl_FragColor.a *= color.r;
    }
  `,
};

const Smoke = ({ speed, position }) => {
  const smokeRef = useRef();

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    smokeRef.current.material.uniforms.time.value = time;
    smokeRef.current.material.uniforms.speed.value = speed;
  });

  return (
    <mesh ref={smokeRef} position={position}>
      <planeBufferGeometry args={[40, 40]} />
      <shaderMaterial args={[SmokeMaterial]} />
    </mesh>
  );
};

export default function Experience() {

  return (
    <>
      <PointLightControls />
   
        <Suspense fallback={null}>
        <ScrollControls pages={4} style={{ opacity: 0 }}>
          <Safe />
        </ScrollControls>
        <Environment files={"env.hdr"} />
      </Suspense>
      {/* <EffectComposer>
        <DepthOfField
          focusDistance={0}
          focalLength={0.1}
          bokehScale={7}
          height={600}
          blendFunction={BlendFunction.Screen}
          blur={true}
        />
      </EffectComposer> */}
      {/* <Smoke></Smoke> */}

{/* 
      <Stars
        radius={.3}
        depth={80}
        count={5000}
        factor={4}
        saturation={200}
        fade
        speed={3}
      /> */}

 
    </>
  );
}
